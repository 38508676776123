import React from "react"
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import GridContainer from "../GridContainer";
import SectionTitle from "../SectionTitle";
import { ContentstackImg } from "@riotgames/wwpub-components"
import ButtonUI from "../buttons/ButtonUI";
import FullSlide from "./FullSlide";
import { mod } from "../../helpers/utils";

import { colors } from "../../styles/variables";

import { FullCarouselSection } from "./styles";

export const fragment = graphql`
	fragment FullCarouselFragment on Contentstack_jfg_home_page {
		gameLoreCarousel: game_lore_carousel {
			headline
			description
			gameLoreAsset: game_lore_asset {
				image {
					url
				}
				headline
				bodyCopy: body_copy
			}
		}
	}
`

export interface FullCarouselData {
	headline: string;
	description: string;
	gameLoreAsset: [{
		image: {
			url: string;
		},
		headline: string,
		bodyCopy: string,
	}];
}

interface IProps {
	data: FullCarouselData;
}

interface IState {
	slide: number
}

export default class FullCarousel extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)
		this.state = {
			slide: -1
		}
	}

	arrowClick = (dir: number) => {
		let newSlide = mod(this.state.slide + dir, this.props.data.gameLoreAsset.length);

		this.setState({slide: newSlide});
	}

	pagClick = (newSlide: number) => {
		this.setState({slide: newSlide});
	}

	renderAllImages = () => {
		return this.props.data.gameLoreAsset.map((asset, index) => (
			<FullSlide slideImg={asset.image.url} activeSlide={this.state.slide} slideIndex={index} key={`fullSlide-${index}`}/>
		))
	}

	renderAllBullets() {
		const bullets = this.props.data.gameLoreAsset.map((asset, index) => {
			const active = this.state.slide === index ? "active" : "";
			return (
				<div className={`pagBullet ${active}`} key={`bullet-${index}`} onClick={ () => this.pagClick(index)}>
					<div className="inner"></div>
				</div>
			);
		});

		return bullets;
	}

	componentDidMount() {
		// Init first slide
		this.setState({slide: 0});
	}

	render() {
		const { headline, gameLoreAsset, description } = this.props.data;
		const activeSlide = this.state.slide;

		return (
			<FullCarouselSection>
				<div className="headerSection">
					<GridContainer>
						<SectionTitle colorTheme="light" title={headline}>
							{description}
						</SectionTitle>
					</GridContainer>
				</div>
				<div className="carouselBox">
					<div className="carousel">
						{ this.renderAllImages() }
					</div>
					<div className="bulletRow">
						{ this.renderAllBullets() }
					</div>
					<ButtonUI className="arrowLeft" colorTheme="lime" callback={() => this.arrowClick(-1)} buttonType="arrow"></ButtonUI>
					<ButtonUI className="arrowRight" colorTheme="lime" callback={() => this.arrowClick(+1)} buttonType="arrow" flipped={true}></ButtonUI>
				</div>
			</FullCarouselSection>
		)
	}
}
