import styled from "styled-components";
import { colors, breakpoints, eases } from "../../styles/variables";

export const HorizontalGallerySection = styled.section`
	position: relative;
	margin: 75px 0;
	overflow: hidden;

	.row {
		grid-column: 3 / span 10;
	}

	.titleRow {
		margin-bottom: 60px;
		display: flex;
		flex-direction: row;
		.text-header {
			margin-right: 88px;
			color: ${colors.smoke};
			text-align: left;
		}
		.arrows {
			display: flex;
			flex-direction: row;
			align-items: center;
			.arrow-btn {
				margin-right: 20px;
				min-width: 50px;
			}
			.arrow-btn:last-child {
				margin-right: 0;
				margin-left: 20px;
			}
		}
	}

	.slideRow {
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			margin-top: 200px;
		}
		white-space: nowrap;
	}
	.riotSlide {	
		display: inline-block;
		border: 8px solid ${colors.black};
		box-shadow: 8px 8px 0 ${colors.black};
		margin: 0 24px 0 0;
		position: relative;
		cursor: pointer;
		transition: box-shadow 0.5s ease-out, transform 0.5s ${eases.inOut};

		.slideImage {
			width: 400px;
			height: 400px;
			background: center center / 100% auto no-repeat;
			transition: background-size 0.5s ease-out;
		}
		.bottomBox {
			width: 100%;
			padding: 24px 0;

			background-image: linear-gradient(-45deg, ${colors.black} 49%, ${colors.white} 50%);
			background-size: 240% 100%;
			background-position: 100% 0;
			transition: background-position 0.5s ${eases.inOut};
			.text-subheader {
				font-size: 18px;
				text-align: center;
				color: white;
				transition: color 0.5s ease-out;
			}
		}

		&:hover {
			box-shadow: 14px 14px 0 ${colors.smoke};
			.slideImage {
				background-size: 110% auto;
			}
			.bottomBox {
				background-position: 0% 0;
				.text-subheader {
					color: ${colors.black};
				}
			}
		}
	}

	@media (max-width: ${breakpoints.tablet}) {
		margin: 88px 0;
		.row {
			grid-column: 2 / span 7;
		}
		.titleRow {
			width: 75%;
			flex-direction: column;
			margin: 0 0 40px 0;
			.text-header {
				margin-bottom: 40px;
				margin-right: 0px;
			}
		}
		.riotSlide .slideImage {
			width: 400px;
			height: 400px;
		}
	}

	@media (max-width: ${breakpoints.mobile}) {
		margin: 40px 0;
		.row {
			grid-column: 1 / span 2;
		}
		.titleRow {
			margin-bottom: 20px;
			.text-header {
				margin-bottom: 20px;
			}
		}
		.riotSlide .slideImage {
			width: 250px;
			height: 250px;
		}
	}
`