import styled from "styled-components";
import { colors, breakpoints, zIndices } from "../../styles/variables";

export const ChampionSection = styled.section`
	position: relative;
	padding: 7vw 0;
	background: 95% center / 10% auto no-repeat;
	background-color: ${colors.pinkLite};
	.contentDiv {
		grid-column: 1 / span 12;
	}
	.titleBox {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 64px;

		.text-header {
			color: ${colors.purple};
			position: relative;
		}
	}
	.champColumns {
		width: 100%;
		display: flex;
		justify-content: space-around;
		-webkit-justify-content: space-evenly !important;
	}

	@media (max-width: ${breakpoints.tablet}) {
		.contentDiv {
			grid-column: 2 / span 6;
		}
	}
	@media (max-width: ${breakpoints.mobile}) {
		.contentDiv {
			grid-column: 1 / span 2;
		}
		.fluteImage {
			width: 100px;
		}
		.champColumns {
			flex-direction: column;
			align-items: center;
			.champCard {
				width: 100%;
			}
		}
	}
`
