import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

export const StyledDiv = styled.div`
  background: ${colors.maroon};
  color: ${colors.white};
  position: relative;
  font-family: "ProximaNova-Bold";
  font-size: 0.875rem;
  margin: 0;
  padding: 0.938rem 1rem 1rem 1rem;
  text-align: center;
  text-transform: uppercase;

  a {
    color: ${colors.lime};
  }
  @media (max-width: ${breakpoints.mobile}) {
    a {
      display: block;
    }
  }
`;
