import styled from "styled-components";
import { colors, breakpoints, eases } from "../../styles/variables";

export const FeaturesContainer = styled.div`
  position: relative;
  background-color: ${colors.pastel};
  .innerClippedBgnd {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 16.5vw),
      38.25% calc(100% - 7.5vw),
      39% calc(100% - 10.75vw),
      0 calc(100% - 5.25vw)
    );
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding-bottom: 18.5vw;
  }

  .layoutBox {
    width: 100%;
    grid-column: 1 / span 12;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slideBox {
    width: 100%;
    background-color: ${colors.maroon};
    position: relative;
    margin-bottom: 64px;
  }

  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .pagArrow {
      cursor: pointer;
      width: 40px;
      height: 40px;
      &.pagArrowRight {
        transform: scaleX(-1);
      }
      .pagSVG {
        width: 100%;
        height: 100%;
        transition: transform 0.3s ${eases.out};
      }
      .pagArrowPath {
        fill: ${colors.maroon};
      }
      .pagArrowHover {
        fill: ${colors.pink};
        transform: scale(0);
        transition: transform 0.3s ${eases.out};
      }
      &:hover {
        .pagSVG {
          transform: translateX(-5px);
        }
        .pagArrowHover {
          transform: scale(0.5);
        }
      }
    }
    .pagRow {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      -webkit-justify-content: space-evenly !important;
      flex-grow: 2;
      margin: 0 32px;
    }
    .pagWidget {
      margin-right: 24px;
      width: 46px;
      color: ${colors.purple};
      cursor: pointer;

      &:last-of-type {
        margin: 0;
      }
      .pagNum {
        font-family: "ProximaNova-Black";
        font-size: 16px;
        line-height: 1em;
        font-weight: normal;
        transition: all 0.3s ease-out;
      }
      .pagBar {
        position: relative;
        display: block;
        background-color: ${colors.purple};
        height: 6px;
        width: 100%;
        margin: 4px 0 0 0;
        overflow: hidden;
        transform: skewX(-25deg);
      }
      .pagProgress {
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: ${colors.maroon};
        transition: width 0.3s ${eases.inOut};
      }

      &.active {
        color: ${colors.maroon};
        .pagProgress {
          width: 100%;
        }
      }
      &:hover {
        .pagNum {
          transform: translateY(-3px);
        }
        .pagBar {
          transform: skewX(-25deg) scaleY(1.2);
        }
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .layoutBox {
      grid-column: 2 / span 6;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    .layoutBox {
      grid-column: 1 / span 2;
      margin-bottom: 48px;
    }
    .slideBox {
      margin-bottom: 32px;
    }
    .pagination {
      width: 100%;
      .pagArrow {
        width: 20px;
        height: 20px;
      }
      .pagRow {
        margin: 0 10px;
      }
      .pagWidget {
        width: 30px;
        margin: 0;
      }
    }
  }
`;
