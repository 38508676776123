import styled from "styled-components";
import { colors, breakpoints } from "../../../styles/variables";

export const Slide = styled.div<{ref: any}>`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	position: absolute;
	top: 0;
	visibility: hidden;
	border: 4px solid ${colors.maroon};

	// First slide holds shape for the rest
	&:first-of-type {
		position: relative;
	}
	.imageBox {
		flex-basis: 63%;
		max-width: 63%;
		position: relative;
		width: 785px;
		height: 500px;
		overflow: hidden;
		.slideImage {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			background-size: cover;
			background-position: center center;
		}
		.vidPlayer {
			object-fit: cover;
		}
	}
	.copyBox {
		flex-basis: 37%;
		max-width: 37%;
		padding: 64px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;

		.txt1 {
			color: ${colors.pinkLite};
			margin-bottom: 20px;
			opacity: 0;
		}
		.txt2 {
			color: ${colors.white};
			margin-bottom: 20px;
			opacity: 0;
		}
		.txt3 {
			color: ${colors.white};
			opacity: 0;
		}
	}

	&.active {
		visibility: visible;
	}

	@media (max-width: ${breakpoints.tablet}) {
		flex-direction: column;
		.imageBox {
			flex-basis: auto;
			width: 100%;
			max-width: 100%;
			height: 0;
			padding-top: 63%;
		}
		.copyBox {
			flex-basis: auto;
			padding: 32px;
			max-width: 100%;
			.txt1, .txt2 {
				margin-bottom: 10px;
			}
		}
	}

	@media (max-width: ${breakpoints.mobile}) {
		.copyBox {
			padding: 30px 12px;
			max-width: 100%;
			.txt1, .txt2 {
				margin-bottom: 10px;
			}
		}
		.copyBox {
			max-width: 100%;
		}
		
	}
`