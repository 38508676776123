import * as React from 'react'
import styled from 'styled-components';
import { zIndices } from "../styles/variables";

const StyledContainer = styled.div`
    width: 100%;
`

const ClippedContainer = styled.div`
    position: relative;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    z-index: ${zIndices.lightningClip};

    padding-bottom: 11.5vw;
    clip-path: polygon(
        0 0,
        100% 0,
        100% 100%,
        61% calc(100% - 5.5vw),
        61.5% calc(100% - 2.25vw),
        0 calc(100% - 11.5vw)
    );
`

const SVGLightningLines = styled.svg`
    display: block;
    position: absolute; 
    left: 0;
    bottom: -5.6vw;
    width: 100%;
    height: auto;
    z-index: ${zIndices.lightningClip};
    opacity: 0.3;
`
export default class RightLightningClippedContainer extends React.Component {

    render() {
        return (
            <StyledContainer>
                <ClippedContainer>{this.props.children}</ClippedContainer>
                <SVGLightningLines width="2560" height="398" viewBox="0 0 2560 398" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3227.3 396.6L1604.5 160L1621 250L0.5 9" stroke="white"/>
                    <path d="M3230.3 389.6L1610.8 153L1627.3 243L0 1" stroke="white"/>
                </SVGLightningLines>
            </StyledContainer>
            
        )
      }
}
