import React from "react";
import styled from 'styled-components'
import { ContentstackImg } from "@riotgames/wwpub-components"
import { breakpoints, colors } from "../../styles/variables";
import imgLight1 from "../../images/lightning1.png";
import imgLight2 from "../../images/lightning2.png";

const ChampCard = styled.div`
	position: relative;
	width: 30%;
	min-width: 200px;
	background: ${colors.purple};
	padding: 8px;
	cursor: pointer;
	box-shadow: 8px 8px 0 ${colors.purple};
	transition: box-shadow 0.5s ease-out;
	margin-bottom: 30px;

	.lightning {
		position: absolute;
		background: center center / contain no-repeat;
		top: 0;
		right: 0;
		width: 25%;
		height: 25%;
	}
	.lightning-0 {
		background-image: url(${imgLight1});
		left: 98%;
		width: 16%;
		padding-top: 45%;
		top: 20%;
	}
	.lightning-1 {
		background-image: url(${imgLight2});
		right: -9%;
		width: 37%;
		padding-top: 44%;
		top: -7%;
	}
	.champWindow {
		width: 100%;
		padding-top: 100%;
		position: relative;
		overflow: hidden;
	}
	.champImage {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
	}
	.champImage {
		display: flex;
		transition: transform 0.7s ease-out;
	}
	.champImage img {
		display: flex;
		flex: 1;
		margin: auto;
		width: 100%;
		height: auto;
	}
	.text-miniheader {
		color: #fff;
		padding: 24px 0 12px;
		text-align: center;
		text-transform: uppercase;
	}

	&:hover {
		box-shadow: 8px 8px 0 ${colors.maroon};

		.champImage {
			transform: scale(1.1);
		}
	}

    @media (max-width: ${breakpoints.tablet}) {
		.text-miniheader {
			padding: 12px 0;
		}
    }

    @media (max-width: ${breakpoints.mobile}) {
        width: 200px;
        .champWindow {
        	padding-top: 100%;
        }
        .champImage {
        	align-items: center;
        	img {
        		margin: unset;
        	}
        }
    }
`;

interface Props {
	imageSrc: string,
	lightningNo: number
	linkHref: string,
}

export default class ButtonText extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
	}

	openURL = (event: React.MouseEvent): void => {
		window.open(this.props.linkHref, "_new");
	}

	render() {
		let id = this.props.lightningNo;
		const lightClass = `lightning lightning-${id}`;
		const lightImg = id === 0 ? imgLight1 : imgLight2;

		return(
			<ChampCard onClick={ this.openURL }>
				<div className="champWindow">
					<div className="champImage">
						<ContentstackImg image={{url: this.props.imageSrc}} />
					</div>
				</div>
				<div className="text-miniheader">{ this.props.children }</div>
				<div className={lightClass}></div>
			</ChampCard>
		);
	}
}
