import React from "react";
import parse from "react-html-parser";
import { graphql } from "gatsby";
import { StyledDiv } from "./styles";

export const homeFragment = graphql`
  fragment TopBannerFragment on Contentstack_jfg_home_page {
    topBanner: top_banner {
      message
    }
  }
`

export interface TopBannerData {
  message: string
}

interface Props {
  data: TopBannerData
}


const TopBanner: React.FC<Props> = ({ data }) =>(
  <StyledDiv>
    { parse(data.message) }
  </StyledDiv>
)

export default TopBanner;