import React from "react"
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import GridContainer from "../GridContainer";
import SectionTitle from "../SectionTitle";

import { 
    OverviewContainer, 
    backgroundImage,
    LeftContainer,
    RightContainer,
    ContentDiv,
} from "./styles"

export const fragment = graphql`
    fragment GameOverviewFragment on Contentstack_jfg_home_page {
        gameOverview: game_overview {
            headline
            rightCopyBlock: right_copy_block
            leftCopyBlock: left_copy_block
        }
    }
`

export interface OverviewData {
    headline: string
    rightCopyBlock: string
    leftCopyBlock: string
}

interface Props {
    data: OverviewData;
}

export default class Overview extends React.Component<Props> {

    render() {
        const { headline, rightCopyBlock, leftCopyBlock } = this.props.data;

        const leftCopyParagraphs = leftCopyBlock.trim().split(/\n\n+/)
        const rightCopyParagraphs = rightCopyBlock.trim().split(/\n\n+/)

        return (
            <OverviewContainer>
                <ContentDiv>
                    <GridContainer>
                        <LeftContainer>
                            <div className="text-header">{headline}</div>
                            <div className="text-subheader">
                                {leftCopyParagraphs.map((p, i) => (
                                    <p key={i}>{p}</p>
                                ))}
                            </div>
                        </LeftContainer>
                        <RightContainer>
                            <div className="text-paragraph">
                                {rightCopyParagraphs.map((p, i) => (
                                    <p key={i}>{p}</p>
                                ))}
                            </div>
                        </RightContainer>
                    </GridContainer>
                </ContentDiv>
            </OverviewContainer>
        )
    }
}
