import React from "react";
import _ from "lodash";
import { graphql } from "gatsby";

import GridContainer from "../GridContainer";
import FeatureSlide from "../molecules/FeatureSlide/FeatureSlide";
import SectionTitle from "../SectionTitle";
import { FeaturesContainer } from "./styles";
import { mod } from "../../helpers/utils";

import imgBackground from "../../images/backgroundFeatures.jpg";

export const fragment = graphql`
  fragment GameFeaturesFragment on Contentstack_jfg_home_page {
    gameFeatures: game_features {
      headline
      description
      gameFeature: game_feature {
        headline
        copyBlurb: copy_blurb
        thumbnailImage: thumbnail_image {
          url
        }
        inlineVideo: inline_video {
          url
        }
        isVideo: is_video
      }
    }
  }
`;

export interface FeaturesData {
  headline: string;
  description: string;
  gameFeature: [
    {
      headline: string;
      copyBlurb: string;
      thumbnailImage: {
        url: string;
      };
      inlineVideo: {
        url: string;
      };
      isVideo: boolean;
    },
  ];
}

interface Props {
  data: FeaturesData;
}
interface State {
  slide: number;
}

const arrowSVG = (
  <svg className="pagSVG" viewBox="0 0 12 22">
    <path d="M0 11L12 0L12 21Z" className="pagArrowPath" />
    <path d="M0 11L12 0L12 21Z" className="pagArrowHover" transform-origin="12 11" />
  </svg>
);

export default class Features extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      slide: -1,
    };
  }

  arrowClick = (dir: number) => {
    let newSlide = mod(this.state.slide + dir, this.props.data.gameFeature.length);

    this.setState({ slide: newSlide });
  };

  pagClick = (newSlide: number) => {
    this.setState({ slide: newSlide });
  };

  renderPaginationWidgets() {
    const allPaginationWidgets = _.range(this.props.data.gameFeature.length).map((index) => {
      const activeClass = this.state.slide === index ? "active" : "";
      const padNumber = String(index + 1).padStart(2, "0");

      return (
        <div className={`pagWidget ${activeClass}`} key={`pag-${index}`} onClick={() => this.pagClick(index)}>
          <div className="pagNum">{padNumber}</div>
          <div className="pagBar">
            <div className="pagProgress"></div>
          </div>
        </div>
      );
    });

    return allPaginationWidgets;
  }

  renderSlides() {
    const slideData = this.props.data.gameFeature.map((feature) => ({
      img: feature.thumbnailImage.url,
      videoURL: feature.isVideo ? feature.inlineVideo.url : null,
      title: feature.headline,
      desc: feature.copyBlurb,
    }));

    const allImages = slideData.map((slide: any, index: number) => {
      const activeClass = this.state.slide === index ? "active" : "";
      const padNumber = String(index + 1).padStart(2, "0");

      return (
        <FeatureSlide
          slideData={slide}
          slideIndex={index}
          activeSlide={this.state.slide}
          key={`slide-${index}`}
        ></FeatureSlide>
      );
    });
    return allImages;
  }

  componentDidMount() {
    // Init first slide
    this.setState({ slide: 0 });
  }

  render() {
    const { headline, description } = this.props.data;

    return (
      <FeaturesContainer>
        <div className="innerClippedBgnd" style={{ backgroundImage: `url("${imgBackground}")` }}>
          <GridContainer className="gridContainer">
            <SectionTitle colorTheme="dark" title={headline}>
              {description}
            </SectionTitle>
            <div className="layoutBox">
              {/* SLIDES */}
              <div className="slideBox">{this.renderSlides()}</div>

              {/* PAGINATION */}
              <div className="pagination">
                <div className="pagArrow pagArrowLeft" onClick={() => this.arrowClick(-1)}>
                  {arrowSVG}
                </div>
                <div className="pagRow">{this.renderPaginationWidgets()}</div>
                <div className="pagArrow pagArrowRight" onClick={() => this.arrowClick(+1)}>
                  {arrowSVG}
                </div>
              </div>
            </div>
          </GridContainer>
        </div>
      </FeaturesContainer>
    );
  }
}
