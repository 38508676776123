import styled from "styled-components";
import { colors, breakpoints } from "../../styles/variables";

export const PublisherSection = styled.section`
    position: relative;
    padding: 75px 0;
    background-color: ${colors.orange};
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    .buttonRow {
        grid-column: 1 / span 12;
        text-align: center;
        margin: 50px 0 0 0;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            margin: 150px 0 0 0;
        }
    }
    .logoContainer {
        width: 200px;
        margin: 0 auto 50px;

        img, svg { 
            width: 100%;
        }
    }
    @media (max-width: ${breakpoints.tablet}) {
        padding: 88px 0;
        .buttonRow {
            grid-column: 2 / span 6;
        }
        .logoContainer {
            width: 200px;
            margin: 0 auto 64px;
        }
    }
    @media (max-width: ${breakpoints.mobile}) {
        padding: 44px 0;
        .buttonRow {
            grid-column: 1 / span 2;
            margin: 30px 0 0 0;
        }
        .logoContainer {
            width: 150px;
            margin: 0 auto 32px;
        }
    }
`

export const backgroundImage = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
    width: "100%",
    height: "100%",
} as const

export const LeftContainer = styled.div`
    grid-column: 1 / span 5;

    .text-miniheader { 
        color: ${colors.white};
    }

    p {
        line-height: 130%;
        margin: 0;
    }
    p + p { 
        margin-top: 1em;
    }

    @media (max-width: ${breakpoints.tablet}) {
        grid-column: 2 / span 6;
        margin-bottom: 30px;
    }

    @media (max-width: ${breakpoints.mobile}) {
        grid-column: 1 / span 2;
    }
`

export const RightContainer = styled.div`
    grid-column: 7 / span 6;

    p {
        line-height: 170%;
        margin: 0;
    }

    p + p {
        margin-top: 1em;
    }

    .text-paragraph {
        color: ${colors.cream};
        margin-bottom: 24px;
    }

    @media (max-width: ${breakpoints.tablet}) {
        grid-column: 2 / span 6;
    }

    @media (max-width: ${breakpoints.mobile}) {
        grid-column: 1 / span 2;
    }
`
