import styled from "styled-components";
import { breakpoints, colors, zIndices } from "../../styles/variables";

export const HeroSection = styled.section`
  width: 100%;
  position: relative;
  background: ${colors.maroon};
  .backgroundImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: center center / cover no-repeat;
  }
  .char {
    position: absolute;
    background: center center / contain no-repeat;
    pointer-events: none;
  }
  .charLeft {
    max-width: 700px;
    width: 31%;
    padding-top: 20%;
    right: 60%;
    top: 32%;
    z-index: ${zIndices.championImg};
  }
  .charMissile {
    position: absolute;
    max-width: 700px;
    width: 41%;
    padding-top: 17%;
    right: 63%;
    top: 34%;
    z-index: ${zIndices.championImg};
  }
  .charRight {
    max-width: 700px;
    width: 31%;
    padding-top: 26%;
    left: 55%;
  }
  .charBomb {
    max-width: 700px;
    width: 31%;
    padding-top: 26%;
    left: 75%;
    top: 20%;
  }
  .centerCol {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 80px);
    max-width: 1440px;
    padding: 0 105px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: ${colors.maroon};

    .mainLogo {
      width: 45vh;
      max-width: 480px;
    }
    .infoGroup {
      background: radial-gradient(closest-side, rgba(255, 234, 192, 0.8) 50%, transparent 100%);
      text-align: center;
    }
    .available {
      font-size: 20px;
      margin-bottom: 16px;
    }

    .text-paragraph {
      text-align: center;
      margin-bottom: 16px;
      max-width: 600px;
    }

    .platforms {
      margin-bottom: 16px;
      svg {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin: 0 8px;
        position: relative;
      }
      path {
        fill: ${colors.maroon};
        transition: fill 0.3s ease-out;
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .centerCol {
      padding: 0 70px;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    .charLeft {
      right: auto;
      left: 0;
      top: 75%;
      width: 41%;
      padding-top: 30%;
    }
    .charMissile {
      right: auto;
      left: -10%;
      top: 80%;
      width: 51%;
    }
    .charRight {
      left: auto;
      right: 0;
    }
    .centerCol {
      padding: 0 24px;
      .mainLogo {
        width: 80%;
        max-width: 300px;
      }
      .available {
        font-size: 18px;
        line-height: 130%;
        margin-bottom: 8px;
      }
      .text-paragraph {
        line-height: 1.5em;
        margin-bottom: 8px;
      }
      .platforms {
        display: none;
      }
    }
  }
  @media (max-height: 700px) {
    .centerCol .platforms {
      display: none;
    }
  }
`;
